var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c(
                  "b-tabs",
                  { attrs: { "content-class": "p-3 text-muted" } },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "border-0",
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-1-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Übersicht")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("h6", [
                              _vm._v(
                                "Fragebogen Design - Integrierung projektiver Fragen"
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                " Projektive Fragen sind ein wichtiger Teil der Redem Technologie. Bei einer projektiven Frage, geht es um die Einschätzung eines Befragten, wie andere ein Frage beantworten werden. In dieser Anleitung finden Sie alle wichtigen Informationen zur Formulierung und Funktionsweisen der projektiven Fragen. "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-12 mt-3" }, [
                            _c("h6", [
                              _vm._v(
                                " Allgemeines was Sie über projektive Fragen wissen müssen. "
                              ),
                            ]),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Bei den Projektionen geht es um eine grobe Schätzung der Befragten über die Antworten von anderen Befragten. "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  " Die Projektion darf auf maximal 1 Frage bezogen sein. Dadurch ist die projektive Frage als Zusatzfrage zu sehen. "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  " Die “Bezugsfrage” muss eine Single-Choice Frage sein. Fragen mit Mehrfachauswahl werden derzeit nicht unterstützt. "
                                ),
                              ]),
                              _c("li", [
                                _vm._v(
                                  " Die Formulierung der projektiven Fragen kann beliebig angepasst werden, solange die Erfordernisse der einzelnen Projektions-Typen eingehalten werden. "
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-md-12 mt-3" }, [
                            _c("h6", [_vm._v("Projektions-Typen")]),
                            _c("p", [
                              _vm._v(
                                " Wir unterscheiden nach drei verschiedenen “Projektions-Typen” bei einer projektiven Frage. Je nach Anwendungsfall ist einer dieser Projektions-Typen zu wählen. "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-11",
                                                  modifiers: {
                                                    "accordion-11": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Vollständig")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-11",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Die Befragten geben an, wie sich die Antworten der anderen auf die unterschiedlichen Antwortoptionen verteilen werden (Antwort 1: 50%, Antwort 2: 20%, Antwort 3: 30%). "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-12",
                                                  modifiers: {
                                                    "accordion-12": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Durchschnitt")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-12",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Die Befragten geben an, wie eine numerische Skala durschnittlich bzw. mehrheitlich beantwortet wird. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-13",
                                                  modifiers: {
                                                    "accordion-13": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [_vm._v("Übereinstimmung")]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-13",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Die Befragten geben an, welcher Prozentsatz der anderen Befragten mit ihrer gewählten Antwort übereinstimmen. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-2-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("FAQ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-21",
                                                  modifiers: {
                                                    "accordion-21": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Warum werden projektive Fragen benötigt?"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-21",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Der Großteil unserer Algorithmen arbeitet mit projektiven Angaben. Dabei können vor allem Verhaltensweisen wie Ehrlichkeit, Informations-Differenzen zwischen Befragten oder auch oberflächliches Antwortverhalten abgeleitet und gemessen werden. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-22",
                                                  modifiers: {
                                                    "accordion-22": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Wie viele projektive Fragen sollen in einen Fragebogen hinzugefügt werden?"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-22",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Wir empfehlen je nach Fragebogenlänge, 3-5 projektive Fragen in einen Fragebogen zu integrieren. Eine Möglichkeit ist zum Beispiel - 1 Frage am Anfang, 1 Frage in der Mitte und 1 Frage am Ende der Umfrage. Bei Mehrthemenbefragungen, sollten Sie mindestens 1 projektive Frage für jedes Thema hinzufügen. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-23",
                                                  modifiers: {
                                                    "accordion-23": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Kann ich Redem auch ohne projektive Fragen verwenden?"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-23",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Derzeit muss jeder Fragebogen mindestens 1 projektive Frage enthalten um die Software zu verwenden. Zukünftig wird es aber möglich sein, dass Teile der Redem-Technologie auch ohne projektive Fragen auskommen. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-24",
                                                  modifiers: {
                                                    "accordion-24": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Auf WEN oder WAS muss sich die projektive Frage beziehen?"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-24",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Die Projektion muss sich immer auf eine klar definierte Zielgruppe beziehen, wie z.B. die österreichische Bevölkerung. Damit wird sichergestellt, dass alle Befragten, sich bei Ihrer Projektion auf die gleiche Population beziehen. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-25",
                                                  modifiers: {
                                                    "accordion-25": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "An welche Fragen sollte eine projektive Frage angehängt werden"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-25",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Projektive Fragen sollten vor allem an wichtige Kernfragen angehängt werden, die inhaltlich am relevantesten sind - zum Beispiel NPS bei Kundenbefragungen. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c(
                              "div",
                              { attrs: { role: "tablist" } },
                              [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-card-header",
                                      {
                                        attrs: {
                                          "header-tag": "header",
                                          role: "tab",
                                        },
                                      },
                                      [
                                        _c("h6", { staticClass: "m-0" }, [
                                          _c(
                                            "a",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.accordion-26",
                                                  modifiers: {
                                                    "accordion-26": true,
                                                  },
                                                },
                                              ],
                                              staticClass: "text-dark",
                                              attrs: {
                                                href: "javascript: void(0);",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Bei welchen Fragen kann eine projektive Frage nicht angehängt werden?"
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "accordion-26",
                                          visible: "",
                                          accordion: "my-accordion",
                                          role: "tabpanel",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-body",
                                          [
                                            _c("b-card-text", [
                                              _c("ul", [
                                                _c("li", [
                                                  _vm._v(
                                                    " Eine projektive Frage kann beispielsweise nicht an eine demographische Abfrage angehängt werden. Auch Fragen, die sich auf Allgemeine Angaben beziehen, eignen sich nicht als Bezugsfrage. "
                                                  ),
                                                ]),
                                              ]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-3-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Vollständige Projektion")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Bei dieser Art der projektiven Frage, muss der Befragte eine vollständige Angabe über die Antwortverteilung der anderen Teilnehmer machen. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Die Frage soll folgendermaßen aufgebaut sein "
                                ),
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      " Und wie glauben Sie, stehen { Zielgruppe einsetzen - z.B österr. Bevölkerung } zu dieser Frage? Bitte schätzen Sie die Anteile so, dass sie gemeinsam 100% ergeben! "
                                    ),
                                    _c("p", { staticClass: "mt-3" }, [
                                      _vm._v(" 35% Antwort 1 "),
                                      _c("br"),
                                      _vm._v(" 25% Antwort 2 "),
                                      _c("br"),
                                      _vm._v(" 10% Antwort 3 "),
                                      _c("br"),
                                      _vm._v(" 30% Antwort 4 "),
                                      _c("br"),
                                      _vm._v(" ---------------- "),
                                      _c("br"),
                                      _vm._v(" 100% "),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Die Stärke dieses Projektions-Typs ist der Detailgrad der Projektion. Durch die genaue Angabe der Antwortverteilung, können mehrere Qualitätskriterien abgeleitet werden, was zu einem genaueren Ergebnis führt. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Der Vollständige Projektions-Typ eignet sich sehr gut für Fragen bis 8 Antwortmöglichkeiten. Bei mehr als 8 Projektions-Angaben wird die Aufgabe für die Befragten zu komplex. "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-3-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Durchschnittliche Projektion")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Bei dieser Art der Projektion geben die Befragten eine Schätzung ab, wie der Durchschnitt beziehungsweise die Mehrheit der anderen Befragten eine Skala beantworten werden. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Die Antwortmöglichkeiten der projektiven Frage sind hier ident mit der Bezugsfrage. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(" Aufbau Frage: "),
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      " Schätzen Sie, wie { Zielgruppe einfügen - z.B. andere Kunden } diese Frage durchschnittlich beantwortet haben! "
                                    ),
                                    _c("p", { staticClass: "mt-3" }, [
                                      _vm._v(" ____ Ja "),
                                      _c("br"),
                                      _vm._v(" _X__ Eher Ja "),
                                      _c("br"),
                                      _vm._v(" ____ Eher Nein "),
                                      _c("br"),
                                      _vm._v(" ____ Nein "),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Dieser Projektions-Typ wurde speziell für Fragen, mit numerischen Antwortoptionen entwickelt und kann auch nur für diese verwendet werden. "
                                ),
                                _c("br"),
                                _vm._v(
                                  " z.B. Likert Skalen (Sehr zufrieden - Gar nicht zufrieden, NPS, 0-100 Skala) "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Der Vorteil dieses Projektions-Typs liegt in der einfachen Anwendung. Es wird lediglich 1 Angabe des Befragten benötigt. "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [
                                    _c("i", {
                                      staticClass: "mdi mdi-numeric-3-circle",
                                    }),
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Übereinstimmende Projektion")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      },
                      [
                        _c("div", { staticClass: "row mt-3" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  " Bei dieser Art der Projektion geben Befragte eine Schätzung ab, wie viel Prozent der anderen Befragten mit ihrer Antwort übereinstimmen werden "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Eine Frage sollte folgendermaßen aussehen: "
                                ),
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      " Schätzen Sie, wie viel Prozent der { Zielgruppe einsetzen - z.B. Mitarbeiter im Unternehmen}, mit Ihrer Antwort übereinstimmen werden (0-100%). "
                                    ),
                                    _c("p", { staticClass: "mt-2" }, [
                                      _vm._v("_55_% "),
                                      _c("br"),
                                    ]),
                                  ]),
                                ]),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Dieser Projektions-Typ eignet sich gut bei Fragen mit einer hohen Anzahl an kategorischen Antwortmöglichkeiten. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Aufgrund des geringen Befragten-Inputs, ist dieser Projektions-Typ jedoch weniger aussagekräftig als beispielsweise der Vollständige Projektions-Typ. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Der große Vorteil dieses Projektionstyps liegt jedoch in der sehr einfachen Anwendbarkeit und den breiten Einsatzmöglichkeiten. "
                                ),
                              ]),
                              _c("li", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " Beispiele für Projektions-Typ Übereinstimmung "
                                ),
                                _c("ul", [
                                  _c("li", [
                                    _vm._v(
                                      " Schätzen Sie, wie viel Prozent der {Zielgruppe einsetzen}, mit Ihrer Antwort übereinstimmen werden (0-100%). "
                                    ),
                                  ]),
                                  _c("li", [
                                    _vm._v(
                                      " Was werden die {Zielgruppe einsetzen} sagen? Wie viel Prozent werden die gleiche Antwort wie Sie wählen (0-100%)? "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }